"use client";

import LinkInternal from "@/components/LinkInternal";
import Shell from "@/components/Shell";
import { ErrorComponent, ErrorOption } from "@/components/error";
import messages from "@/components/templates/event-page/messages";
import NotFoundPage from "@/components/templates/not-found-page";
import ROUTES from "@/constants/routes";
import { Providers } from "@/state/wrapWithProviders";

type ErrorProps = {
	error: Error;
	reset: () => void;
};

export default function Error({ error, reset }: ErrorProps) {
	const {
		title,
		message,
		option_title,
		option_contact,
		option_refresh,
		option_wait,
	} = messages.error;

	const { pathname } = window?.location;

	if (error.message === "NEXT_NOT_FOUND") {
		return <NotFoundPage />;
	}

	return (
		<Providers>
			<Shell isFooterTopLineDisabled pathname={pathname}>
				<div className="error-wrap w-50">
					<ErrorComponent
						title={title}
						message={message}
						optionsTitle={option_title}
						isEventError={false}
						error={error.message}
					>
						<ErrorOption icon="oa-restart">{option_refresh}</ErrorOption>
						<ErrorOption icon="oa-loader">{option_wait}</ErrorOption>
						<ErrorOption icon="oa-email">
							<LinkInternal
								to={`${ROUTES.contact}/?category=0&subject=${encodeURI(
									`Error on page ${pathname}`
								)}&message=${encodeURI(
									`An error occured on page ${pathname}\n\nDetails: ${error.message}`
								)}`}
							>
								{option_contact}
							</LinkInternal>
						</ErrorOption>
					</ErrorComponent>
				</div>
			</Shell>
		</Providers>
	);
}
